<template>
  <el-dialog
    v-model="winShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="t('order.order_detail')"
    width="900px">
    <!-- 详情 -->
    <custem-detail :loading="detailLoading" :descriptions="descriptions" />
    <!-- 分配历史表格/详情显示 -->
    <div v-if="!orderType">
      <el-divider content-position="center">{{ t('order.allocation_history') }}</el-divider>
      <div class="tables">
        <custem-table
          ref="table"
          :tableInfo="tableInfo"
          :tableLoading="tableLoading"
          height="400px"
          @getList="getAssignmentList"></custem-table>
      </div>
    </div>
    <!-- 销售分配订单填写订单分配周期、使用周期 -->
    <div v-if="orderStatus === 0 && $userRole(['SalesMan']) && !!orderType" class="margin-t4">
      <custem-form ref="form" :loading="formLoading" :formData="formData" :formRules="formRules">
        <template #assign_period>
          <el-input :placeholder="t('form.inputPlaceholder')" v-model="formData.assign_period.value" type="number"
            autocomplete="off">
            <template #append>
              <el-select v-model="unitsAssign" :placeholder="t('form.selectPlaceholder')" style="width: 120px">
                <el-option v-for="(item, index) in units" :key="index" :label="item.text" :value="item.value" />
              </el-select>
            </template>
          </el-input>
        </template>
        <template #use_period>
          <el-input :placeholder="t('form.inputPlaceholder')" v-model.number="formData.use_period.value" type="number"
            autocomplete="off">
            <template #append>
              <el-select v-model="unitsUse" :placeholder="t('form.selectPlaceholder')" style="width: 120px">
                <el-option v-for="(item, index) in units" :key="index" :label="item.text" :value="item.value" />
              </el-select>
            </template>
          </el-input>
        </template>
      </custem-form>
    </div>
    <!-- 底部按钮 -->
    <template #footer>
      <div class="dialog-footer">
        <template v-if="orderType === 'confirm'">
          <!-- 确认 -->
          <el-button v-if="orderStatus === 0 && $userRole(['SalesMan'])" @click="processOrder(1)"
            :loading="submitLoading" type="primary">
            {{ t('form.confirm') }}
          </el-button>
          <!-- 同意 -->
          <el-button v-if="orderStatus === 1 && $userRole(['GeneralAdmin'])" @click="processOrder(2)"
            :loading="submitLoading" type="primary">
            {{ t('form.agree') }}
          </el-button>
          <!-- 拒绝 -->
          <el-button
            v-if="(orderStatus === 0 && $userRole(['SalesMan'])) || (orderStatus === 1 && $userRole(['GeneralAdmin']))"
            :loading="submitLoading" type="danger" @click="processOrder(9)">
            {{ t('form.reject') }}
          </el-button>
        </template>
        <el-button @click="closeWin">{{ orderType === 'confirm' ? t('form.cancel') : t('form.close') }}</el-button>
      </div>
    </template>
    <!-- 分配详情 -->
    <AssignmentDetail ref="assignmentDetail" />
  </el-dialog>
</template>

<script setup>
import { ref, inject, reactive, getCurrentInstance, nextTick } from 'vue'
import AssignmentDetail from '../../Assignment/components/Detail.vue'
import { AuthManagement } from '@/utils/api'
import { rules } from '@/utils/rules'
import { ElMessageBox, ElMessage } from 'element-plus'

const t = inject('t')
const { proxy } = getCurrentInstance()
const winShow = ref(false)
const detailLoading = ref(false)
const tableLoading = ref(false) // 分配历史表格loading
const formLoading = ref(false) // 表单loading
const submitLoading = ref(false) // 表单处理loading
const orderType = ref(null) // 订单查看类型
const order_id = ref(null) // 订单id
const orderStatus = ref(null) // 订单状态
const assignmentDetail = ref() // 分配详情ref
const form = ref() // 表单ref
const unitsAssign = ref(1)
const unitsUse = ref(1)
const table = ref()
const emit = defineEmits(['getList'])
const units = [
  { value: 1, text: t('system.day') },
  { value: 2, text: t('system.month') },
  { value: 3, text: t('system.year') }
]
const formRules = reactive({
  assign_period: rules,
  use_period: rules
})
const formData = reactive({
  assign_period: {
    label: t('order.asse_cycle'),
    type: 'slot',
    tips: t('order.asse_cycle_tip')
  },
  use_period: {
    label: t('order.order_cycle'),
    type: 'slot',
    tips: t('order.use_cycle')
  }
})
const descriptions = reactive([
  { // 订单创建时间
    label: t('order.order_create'),
    prop: 'created_at'
  },
  { // 订单编号
    label: t('order.order_code'),
    prop: 'code'
  },
  { // 产品名称
    label: t('product.product_name'),
    prop: 'product_name',
    span: 2
  },
  { // 订单类型
    label: t('order.order_type'),
    prop: 'category_id'
  },
  { // 订单总数
    label: t('order.order_total'),
    prop: 'purchased_value'
  },
  { // 当前可用
    label: t('order.can_use'),
    prop: 'available_value'
  },
  { // 使用周期
    label: t('order.order_cycle'),
    prop: 'use_period'
  },
  { // 分配周期
    label: t('order.asse_cycle'),
    prop: 'assign_period'
  },
  { // 销售人员
    label: t('dashboard.sales'),
    prop: 'sales_name'
  },
  { // 所属企业
    label: t('dashboard.enterprise'),
    prop: 'enterprise_name'
  },
  { // 审批状态
    label: t('order.approval_status'),
    prop: 'status'
  },
  { // 订单状态
    label: t('order.order_status'),
    prop: 'order_status'
  },
  { // 审核通过时间
    label: t('order.approval_at'),
    prop: 'approval_at'
  },
  { // 分配到期时间
    label: t('order.ass_end_time'),
    prop: 'expired_at',
    span: 2
  },
  { // 原订单编号
    label: t('order.original_order_code'),
    prop: 'parent_order_id'
  },
  { // 备注
    label: t('user.notes'),
    prop: 'comment',
    span: 2
  }
])
// 分配历史表格
const tableInfo = reactive({
  header: [
    { // 项目名称
      key: 'project_name',
      label: t('project.project_name'),
      custem: (data) => {
        return data.project && data.project.name ? data.project.name : '-'
      }
    },
    { // 分配数量
      key: 'hosts',
      label: t('order.allocation_count'),
      custem: (data) => {
        return data.content ? `${proxy.$numberToCurrencyNo(data.content.hosts)} ${t('unit.ge')}` : '' - ''
      }
    },
    { // 分配日期
      key: 'created_at',
      label: t('order.allocation_time')
    },
    { // 使用到期时间
      key: 'expired_at',
      label: t('order.expiration_time')
    },
    {
      label: t('table.action'),
      width: '80px',
      fixed: 'right',
      hidden: () => {
        return !proxy.$userRole(['Enterprise'])
      },
      handleFun: [
        {
          name: t('table.detail'),
          fn: (data) => {
            if (!!!assignmentDetail.value) return
            assignmentDetail.value.openWin(data)
          }
        }
      ]
    }
  ],
  data: []
})
// 获取订单详情
const getOrderDetail = async () => {
  detailLoading.value = true
  try {
    const res = await AuthManagement.OrderDetail(order_id.value)
    descriptions.forEach(item => {
      if (item.prop === 'product_name') { // 产品名称
        item.value = res.product ? `${res.product.deployment.name}/${res.product.category.name}/${res.product.name}` : '-'
      } else if (item.prop === 'category_id') { // 订单类型
        item.value = proxy.$formatType(res.category_id, res.params.service_type === 'failback' ? t('order.failback') : '-')
      } else if (item.prop === 'purchased_value') { // 订单类型
        item.value = res.purchased ? `${proxy.$numberToCurrencyNo(res.purchased.hosts.value)} ${t('unit.ge')}` : '-'
      } else if (item.prop === 'available_value') { // 当前可用
        item.hidden = !!!proxy.$userRole(['Enterprise'])
        item.value = res.is_available ? (res.available ? `${proxy.$numberToCurrencyNo(res.available.hosts.value)} ${t('unit.ge')}` : '-') : '-'
      } else if (item.prop === 'use_period') { // 使用周期
        item.value = res.use_period ? `${proxy.$numberToCurrencyNo(res.use_period)} ${t('unit.ge')}` : '-'
      } else if (item.prop === 'assign_period') { // 分配周期
        item.value = res.assign_period ? `${proxy.$numberToCurrencyNo(res.assign_period)} ${Number(res.assign_period) > 1 ? t('system.days') : t('system.day')}` : '-'
      } else if (item.prop === 'sales_name') { // 销售人员
        item.hidden = !!!proxy.$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan'])
        item.value = res.sales ? res.sales.name : '-'
      } else if (item.prop === 'enterprise_name') { // 所属企业
        item.hidden = !!!proxy.$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan'])
        item.value = res.enterprise ? res.enterprise.name : '-'
      } else if (item.prop === 'status') { // 审批状态
        item.value = proxy.$formatState(res.status, 'html')
      } else if (item.prop === 'order_status') { // 订单状态
        item.value = res.is_available ? `<p class="finished approved">${t('order.available')}</p>` : `<p class="faild approved">${t('order.unavailable')}</p>`
      } else if (item.prop === 'approval_at' || item.prop === 'expired_at') { // 审核通过时间
        item.hidden = res.status === 1
        item.value = res[item.prop] || '-'
      } else if (item.prop === 'parent_order_id') { // 原订单编号
        item.hidden = res.category_id !== 1002
        item.value = res[item.prop] || '-'
      } else {
        item.value = res[item.prop] || '-'
      }
    })
    orderStatus.value = res.status
    detailLoading.value = false
  } catch (error) {
    detailLoading.value = false
  }
}
// 获取分配历史表格
const getAssignmentList = async (search = {}) => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.AssignmentList({
      filter_order_id: order_id.value,
      ...search
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}
// 审批订单 审批、确认、拒绝
const processOrder = async (status) => {
  // status === 9 拒绝订单
  if (status === 9) {
    if (form.value) form.value.clearForm()
    // 拒绝订单
    ElMessageBox.prompt(t('form.inputPlaceholder'), t('user.notes'), {
      confirmButtonText: t('form.submit'),
      cancelButtonText: t('form.cancel')
    }).then(({ value }) => {
      if (value)
        subRequest({
          status: status,
          comment: value
        })
      else subRequest({ status: status })
    }).catch(() => { })
  } else {
    if (status === 1) {
      // 销售校验使用周期和分配周期是否填写
      const res = await form.value.submitForm()
      if (!res) return
    }
    processWin(status)
  }
}
// 确定、审核通过弹框
const processWin = (status) => {
  let tips = ''
  switch (status) {
    case 1: tips = t('order.confirm_order'); break;
    case 2: tips = t('order.approve_order'); break;
    default: tips = t('order.action_order'); break;
  }
  ElMessageBox.confirm(
    tips,
    t('form.tip'),
    {
      confirmButtonText: t('form.submit'),
      cancelButtonText: t('form.cancel'),
      type: 'warning',
    }
  ).then((action) => {
    if (action === 'confirm') {
      if (status === 1) {
        // 销售确认
        subRequest({
          status: status,
          use_period: computDays(formData.use_period.value, unitsUse.value),
          assign_period: computDays(formData.assign_period.value, unitsAssign.value)
        })
      } else {
        // 运营审批
        subRequest({ status: status })
      }
    }
  }).catch()
}
// 审批请求接口
const subRequest = async (datas = {}) => {
  submitLoading.value = true
  try {
    await AuthManagement.OrderUpdate(order_id.value, { ...datas })
    ElMessage.success(t('form.handleSuccess'))
    emit('getList')
    closeWin()
    submitLoading.value = false
  } catch (error) {
    submitLoading.value = false
  }
}
// 天数计算
const computDays = (day, type) => {
  day = Number(day)
  const days = {
    1: day,
    2: parseInt(day) * 30,
    3: parseInt(day) * 365
  }
  return days[type] || 0
}
// 打开弹框
const openWin = async (data, type = null) => {
  winShow.value = true
  orderType.value = type
  order_id.value = data.id
  getOrderDetail()
  await nextTick()
  table.value.resetCurrent()
  getAssignmentList()
}

// 关闭弹框
const closeWin = () => {
  if (!!orderType.value && form.value) form.value.clearForm()
  unitsAssign.value = 1
  unitsUse.value = 1
  winShow.value = false
}
defineExpose({
  openWin
})
</script>

<style lang="scss" scoped>
:deep(.el-input-group--append>.el-input__wrapper) {
  position: relative !important;
}

:deep(.el-input .el-input__icon) {
  display: none !important;
}
</style>