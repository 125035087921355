<template>
  <custem-form
    ref="form"
    :loading="formLoading"
    :formData="formData"
    :formRules="formRules"
    @change="change" />
    <div class="dialog-footer">
      <el-button
        :disabled="formLoading"
        :loading="submitLoading"
        @click="onSubmit"
        type="primary">{{ addType === 'SalesMan' && !!!edit_id ? t('form.next') : t('form.submit') }}</el-button>
      <el-button @click="emit('onClose')">{{ t('form.cancel') }}</el-button>
    </div>
</template>

<script setup>
import { ref, reactive, inject, watch, nextTick, getCurrentInstance } from 'vue'
import { rules } from '@/utils/rules'
import { userManagement, enterpriseManagement } from '@/utils/api'
import { ElMessage } from 'element-plus'
import store from '@/store'
const form = ref()
const t = inject('t')
const formLoading = ref(false) // 表单loading
const submitLoading = ref(false) // 提交loading
const emit = defineEmits(['getList', 'nextStep', 'onClose'])
const edit_id = ref('') // 编辑id
const { proxy } = getCurrentInstance()
const props = defineProps({
  // 添加类型
  addType: {
    type: String,
    default: ''
  },
  winShow: {
    type: Boolean,
    default: false
  }
})
// 运维表单
const generalForm = reactive({
  name: { // 用户登录名
    label: t('user.username'),
    type: 'text',
    value: ''
  },
  password: { // 登录密码
    label: t('user.password'),
    type: 'password',
    value: ''
  },
  password1: { // 确认密码
    label: t('user.confirm_password'),
    type: 'password',
    value: ''
  },
  nick_name: { // 用户昵称
    label: t('user.nick_name'),
    type: 'text',
    value: ''
  },
  liaison_man: { // 联系人姓名
    label: t('user.liaison_name'),
    type: 'text',
    value: ''
  },
  liaison_phone: { // 联系人手机号
    label: t('user.liaison_phone'),
    type: 'text',
    value: ''
  },
  liaison_mail: { // 联系人邮箱
    label: t('user.liaison_mail'),
    type: 'text',
    prop: 'liaison_mail',
    value: ''
  },
  comment: { // 备注
    label: t('user.notes'),
    type: 'text',
    value: ''
  }
})
// 销售表单
const salesForm = reactive({
  name: { // 用户登录名
    label: t('user.username'),
    type: 'text',
    value: ''
  },
  password: { // 登录密码
    label: t('user.password'),
    type: 'password',
    value: ''
  },
  password1: { // 确认密码
    label: t('user.confirm_password'),
    type: 'password',
    value: ''
  },
  nick_name: { // 用户昵称
    label: t('user.nick_name'),
    type: 'text',
    value: ''
  },
  liaison_man: { // 联系人姓名
    label: t('user.liaison_name'),
    type: 'text',
    value: ''
  },
  liaison_phone: { // 联系人手机号
    label: t('user.liaison_phone'),
    type: 'text',
    value: ''
  },
  phone: { // 钉钉手机号
    label: t('user.dingtalk_phone'),
    type: 'text',
    value: ''
  },
  liaison_mail: { // 联系人邮箱
    label: t('user.liaison_mail'),
    type: 'text',
    prop: 'liaison_mail',
    value: ''
  },
  comment: { // 备注
    label: t('user.notes'),
    type: 'text',
    value: ''
  }
})
// 企业用户表单
const enterpriseForm = reactive({
  parent_id: { // 所属销售
    label: t('user.sales'),
    type: 'select',
    value: '',
    disabled: !!edit_id.value,
    hidden: !proxy.$userRole(['GeneralAdmin']),
    options: []
  },
  enterprise_id: { // 所属企业
    label: t('dashboard.enterprise'),
    type: 'select',
    value: '',
    options: []
  },
  name: { // 用户登录名
    label: t('user.enterprise_username'),
    type: 'text',
    value: ''
  },
  password: { // 登录密码
    label: t('user.password'),
    type: 'password',
    value: ''
  },
  password1: { // 确认密码
    label: t('user.confirm_password'),
    type: 'password',
    value: ''
  },
  nick_name: { // 用户昵称
    label: t('user.enterprise_nick_name'),
    type: 'text',
    value: ''
  },
  liaison_man: { // 联系人姓名
    label: t('user.enterprise_Liaison'),
    type: 'text',
    value: ''
  },
  liaison_phone: { // 联系人手机号
    label: t('user.enterprise_liaison_phone'),
    type: 'text',
    value: ''
  },
  tutelage: { // 销售代管
    label: t('user.custody'),
    type: 'switch',
    value: true,
    options: [],
    tips: t('user.custody_tip')
  },
  liaison_mail: { // 联系人邮箱
    label: t('user.enterprise_liaison_mail'),
    type: 'text',
    prop: 'liaison_mail',
    value: ''
  },
  comment: { // 备注
    label: t('user.notes'),
    type: 'text',
    value: ''
  }
})
// 企业子用户表单
const subordinateForm = reactive({
  name: { // 用户登录名
    label: t('user.username'),
    type: 'text',
    value: ''
  },
  password: { // 登录密码
    label: t('user.password'),
    type: 'password',
    value: ''
  },
  password1: { // 确认密码
    label: t('user.confirm_password'),
    type: 'password',
    value: ''
  },
  nick_name: { // 用户昵称
    label: t('user.nick_name'),
    type: 'text',
    value: ''
  },
  liaison_man: { // 联系人姓名
    label: t('user.liaison_name'),
    type: 'text',
    value: ''
  },
  liaison_phone: { // 联系人手机号
    label: t('user.liaison_phone'),
    type: 'text',
    value: ''
  },
  liaison_mail: { // 联系人邮箱
    label: t('user.liaison_mail'),
    type: 'text',
    prop: 'liaison_mail',
    value: ''
  },
  comment: { // 备注
    label: t('user.notes'),
    type: 'textarea',
    value: ''
  },
  visible: { // 共有子用户
    label: t('user.common_sub_user'),
    type: 'switch',
    value: false
  }
})
// 表单
const formData = ref({})
const formMapping = {
  'GeneralAdmin': generalForm,
  'SalesMan': salesForm,
  'Enterprise': enterpriseForm,
  'Subordinate': subordinateForm
}
// 表单验证规则
const formRules = ref({
  name: rules,
  password: rules,
  nick_name: rules,
  liaison_man: rules,
  liaison_mail: rules,
  liaison_phone: rules,
  phone: rules,
  tutelage: rules,
  enterprise_id: rules,
  parent_id: rules,
  password1: [{
    required: true,
    trigger: ['blur', 'change'],
    validator: (rule, modleVal, callback) => {
      if (!!!modleVal.value) {
        callback(new Error(t('form.emptyErrMsg')))
      } else if (modleVal.value !== formData.value.password.value) {
        callback(new Error(t('form.pwdMessage')))
      } else {
        callback()
      }
    }
  }]
})
watch(() => props.winShow, async (newVal) => {
  if (!newVal) return
  formData.value = formMapping[props.addType]
  await nextTick()
  form.value.clearForm()
  formData.value['name'].disabled = !!edit_id.value
  formData.value['password1'].hidden = !!edit_id.value
  formRules.value.password = !!edit_id.value ? [] : rules
  // 创建企业用户表单渲染
  if (props.addType !== 'Enterprise') return
  if (proxy.$userRole(['SalesMan'])) { // 销售用户创建企业时
    formData.value.parent_id.value = store.state.users.id
    getEnterprise({ filter_sales_user_id: store.state.users.id })
    return
  }
  if (proxy.$userRole(['GeneralAdmin'])) {
    getSalesList()
  }
}, { deep: true, immediate: true })

// 表单提交
const onSubmit = async () => {
  const res = await form.value.submitForm()
  let checkUser = null
  if (!res) return
  if (!edit_id.value) {
    checkUser = await checkUsers()
    if (!checkUser) return
  }
  submitLoading.value = true
  const postData = {
    role_id: props.addType,
    params: {}
  }
  for (const key in formData.value) {
    postData[key] = formData.value[key].value
    postData.params = {
      comment: formData.value['comment'].value,
      liaison_man: formData.value['liaison_man'].value,
      liaison_mail: formData.value['liaison_mail'].value,
      liaison_phone: formData.value['liaison_phone'].value
    }
  }
  if (postData.comment) delete postData.comment
  if (postData.liaison_man) delete postData.liaison_man
  if (postData.liaison_phone) delete postData.liaison_phone
  if (postData.tutelage !== undefined) delete postData.tutelage
  for (const key in postData) {
    if (postData[key] === undefined || postData[key] === '') {
      delete postData[key]
    }
    if (postData.params && (postData.params[key] === undefined ||  postData.params[key] === '')) {
      delete postData.params[key]
    }
  }
  if (props.addType === 'SalesMan') { // 销售需要提交钉钉手机号
    postData.phone = formData.value['phone'].value
  }
  if (props.addType === 'Enterprise') { // 企业用户需要提交是否代管
    postData.params.tutelage = formData.value['tutelage'].value
  }
  try {
    if (!!edit_id.value) { // 编辑
      await userManagement.roleUserUpdate(edit_id.value, postData)
      ElMessage.success(t('table.modify_success'))
      emit('onClose')
      emit('getList')
    } else { // 创建
      if (props.addType !== 'SalesMan') {
        await userManagement.roleUserCreate(postData)
        ElMessage.success(t('table.create_success'))
        emit('onClose')
        emit('getList')
      } else {
        emit('nextStep', postData)
      }
    }
    submitLoading.value = false
  } catch (error) {
    submitLoading.value = false
  }
}
const clearForm = async () => {
  edit_id.value = ''
 await nextTick()
 form.value.clearForm()
}
const change = async ({ item, prop, event }) => {
  // 需要验证用户名是否存在
  if (prop === 'name') {
    await checkUsers()
  } else if (prop === 'parent_id') {
    getEnterprise({ filter_sales_user_id: item.value })
  }
}
// 校验用户是否存在
const checkUsers = () => {
  return new Promise(async (resolve) => {
    const res = await userManagement.checkUser({
      type: 'name',
      name: formData.value['name'].value
    })
    formData.value['name'].errorMsg = res.reason
    resolve(!!res.success)
  })
}
// 获取用户详情
const getUserDetail = async (id) => {
  formLoading.value = true
  edit_id.value = id
  const res = await userManagement.roleUserDetail(edit_id.value)
  if (proxy.$userRole(['SalesMan'])) { // 销售用户创建企业时
    getEnterprise({ filter_sales_user_id: res.parent_id })
  }
  await nextTick()
  for (const key in formData.value) {
    formData.value[key].value = res[key] || res.params[key]
    if (key === 'tutelage') formData.value[key].value = res.params[key] || false
  }
  formLoading.value = false
}
// 获取企业列表
const getEnterprise = async (search = {}) => {
  formData.value.enterprise_id.loading = true
  try {
    const res = await enterpriseManagement.EnterpriseList({
      ...search,
      is_paginate: false,
      filter_status: 1
    })
    formData.value.enterprise_id.options = res.items
    formData.value.enterprise_id.loading = false
  } catch (error) {
    formData.value.enterprise_id.loading = false
  }
}

// 获取销售列表
const getSalesList = async () => {
  formData.value.parent_id.loading = true
  try {
    const res = await userManagement.roleUserList({
      filter_role_alias: 'SalesMan',
      is_paginate: false
    })
    formData.value.parent_id.options = res.items
    formData.value.parent_id.loading = false
    formData.value.parent_id.disabled = !!edit_id.value
  } catch (error) {
    formData.value.parent_id.loading = false
  }
}

defineExpose({
  clearForm,
  getUserDetail
})
</script>